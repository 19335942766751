<template>
  <auth-layout>
    <div class="auth-contents">
      <a-card :bordered="false" layout="vertical">
        <sd-heading as="h3">{{$t('views.forgotPassword.title')}}</sd-heading>
        <div class="color-secondary mb-10">
            {{$t('views.forgotPassword.message')}}
        </div>
        <app-form
          ref="forgotPasswordForm"
          :hasAlertList="true"
          :fields="fields"
          :hideRequiredMark="true"
          :loading="loading"
          :submitButton="submitButton"
          @submitted="handleSubmit"
          :rules="rules"
        >
          <template v-slot:alertList>
            <sd-alerts v-if="!$_.isEmpty(error)" message="" :description="$t(error)" :closable="false" type="error"/>
          </template>

          <template v-slot:actions>
            <div class="auth-form-action">
              <router-link class="forgot-pass-link" :to="{name: loginLink}">
                {{$t('views.forgotPassword.returnToLogin')}}
              </router-link>
            </div>
          </template>
        </app-form>
      </a-card>
    </div>
  </auth-layout>
</template>
<script>
import {
  computed, watch, ref, reactive
} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {AuthLayout} from './style';
import {required} from '@/helpers/validationRules';
import {useI18n} from 'vue-i18n';

export default {
  name: 'Login',
  components: {
    AuthLayout
  },
  setup() {
    const {
      state, commit, dispatch
    } = useStore();
    const route = useRoute();

    const forgotPasswordForm = ref(null);
    const error = computed(() => state.layout.error);

    const currentRole = ref('');

    const setRoles = () => {
      currentRole.value = route.meta.key;
      commit('setUserType', route.meta.userType);
      commit('setError', '');
      forgotPasswordForm?.value?.cancelSubmit();
    };

    watch(() => route.path, () => {
      if (route.name === 'BackOfficeForgotPassword' || route.name === 'AgentForgotPassword' || route.name === 'ClientForgotPassword') {
        setRoles();
      }
    }, {immediate: true});

    const fields = {
      email: {
        type: 'text',
        class: 'mb-15',
        size: 'large',
        label: 'models.user.attributes.email',
        fieldValue: '',
        span: 24
      }
    };

    const {t} = useI18n();
    const rules = {
      email: [
        {
          ...required
        }, {
          type: 'email', message: t('messages.error.emailFormat'), trigger: 'blur'
        }]
    };
    const submitButton = reactive({
      label: 'views.forgotPassword.sendResetInstructions',
      class: 'btn-signin',
      size: 'large',
      type: 'primary',
      block: false
    });
    const loading = ref(false);
    const handleSubmit = (data) => {
      loading.value= true;
      dispatch('forgotPassword', data).then(() => loading.value = false);
    };

    const loginLink = computed(() => {
      switch (currentRole.value) {
      case 'Agent':
        return 'AgentLogin';
      case 'Client':
        return 'ClientLogin';
      case 'BackOffice':
        return 'BackOfficeLogin';
      default:
        return undefined;
      }
    });

    return {
      forgotPasswordForm,
      currentRole,
      fields,
      submitButton,
      loading,
      error,
      handleSubmit,
      loginLink,
      rules
    };
  }
};
</script>
